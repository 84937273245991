import styled from "styled-components";

export const Text = styled.div(
  ({ arabic }) => `
    font-family: ${arabic ? "Cairo" : "Dm Sans"};
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    color: #676767;
    direction: ${arabic ? "rtl" : "ltr"};
    text-align: ${arabic ? "right" : "left"};
  `
);

export const InputComponent = styled.input(
  ({ arabic }) => `
    text-align:${arabic ? "right" : "left"};
    direction: ${arabic ? "rtl" : "ltr"};
    font-family: ${arabic ? "Cairo" : "Dm Sans"};
    margin-bottom: 15px;
    border:none;
    width:392px;
    height: 50px;
    padding:0 20px;
    background: rgba(217, 217, 217, 0.30);
    border-radius: 200px;

    ::placeholder {
      font-family: ${arabic ? "Cairo" : "Dm Sans"};
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      direction:rtl;
      color: #99a5b4;
    }
       @media (max-width: 992px) {
    width: 100%;
  }
  `
);

export const SubjectsContainer = styled.div`
  width: 392px;
  @media (max-width: 992px) {
    width: 100%;
  }
`;

export const BadgeCon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 4px;
  padding: 10px;
  background: #e8e9f5b2;
  border-radius: 61px;
  margin: 15px 10px 0 0;
`;

export const BadgeText = styled.div(
  ({ arabic }) => `
    font-family: ${arabic ? "Cairo" : "Dm Sans"};
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #191d23;
  `
);
