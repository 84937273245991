/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { Container, Nav, Navbar, Button, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import BannerImg from "../Assets/Images/BannerImg";
import BottomLine from "../Assets/Images/BottomLine";
import ChatIcon from "../Assets/Images/ChatIcon";
import { PopupButton } from "react-calendly";
import Logo from "../Assets/Images/Logo";
import Footer from "../Component/Footer/Footer";
import LogoSlider from "../Component/LogoSlider/LogoSlider";

export default function Home() {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let utm_source = params.get("utm_source");
  let utm_content = params.get("utm_content");
  let utm_medium = params.get("utm_medium");
  let utm_campaign = params.get("utm_campaign");
  let utm_term = params.get("utm_term");
  const location = useLocation();
  localStorage.setItem("query", location.search);
  const query = localStorage.getItem("query");

  console.log("query", window.location.href);

  return (
    <div style={{ background: "#fff" }}>
      <Navbar collapseOnSelect expand="lg" sticky="top">
        <Container>
          <a
            // className="whatsapp-fixed-icon-con"
            target="_blank"
            href={"https://wa.me/96566687847"}
            rel="noreferrer"
          >
            <img
              className="mobile-view"
              style={{ width: 28, height: 28 }}
              src="https://storage.googleapis.com/destination_ulearn/courses/WhatsApp_Logo_1%201.png"
              alt="icon"
            />
          </a>
          <Navbar.Brand href="#home">
            <Logo />
          </Navbar.Brand>
          <Navbar.Toggle className="mobile-view">
            <svg
              width="16"
              className="d-flex justify-content-end "
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="2.66667" cy="2.66667" r="2.66667" fill="black" />
              <circle cx="2.66667" cy="13.3332" r="2.66667" fill="black" />
              <circle cx="13.3332" cy="13.3332" r="2.66667" fill="black" />
              <circle cx="13.3332" cy="2.66667" r="2.66667" fill="black" />
            </svg>
          </Navbar.Toggle>
          <Navbar.Collapse className="justify-content-center">
            <Nav.Link href="https://ulearn.study">دورات اللغة</Nav.Link>
            <Nav.Link
              target="_blank"
              href="https://ulearn.study/placement/index"
            >
              اختبار مستوى لغة انجليزية
            </Nav.Link>
            <Nav.Link href="https://g.page/Ulearn-study-abroad">
              لوكيشن المكتب
            </Nav.Link>
            <Nav.Link href={utm_source ? `/career${query}` : "/career"}>
              اختبار تحديد التخصص{" "}
            </Nav.Link>
            <div className="mobile-view" style={{ padding: "0 15px " }}>
              <PopupButton
                className="btn-primary"
                url="https://calendly.com/ulearn/website?hide_gdpr_banner=1"
                utm={{
                  utmCampaign: utm_campaign,
                  utmContent: utm_content,
                  utmMedium: utm_medium,
                  utmSource: utm_source,
                  utmTerm: utm_term,
                }}
                rootElement={document.getElementById("root")}
                text="احجز موعد استشارة هاتفية مجانية"
              />
            </div>
          </Navbar.Collapse>

          <div className="pc-view">
            <div
              className="d-flex justify-content-end"
              style={{ padding: "0 15px " }}
            >
              <PopupButton
                className="btn-primary calendly-btn"
                url="https://calendly.com/ulearn/website?hide_gdpr_banner=1"
                utm={{
                  utmCampaign: utm_campaign,
                  utmContent: utm_content,
                  utmMedium: utm_medium,
                  utmSource: utm_source,
                  utmTerm: utm_term,
                }}
                rootElement={document.getElementById("root")}
                text="احجز موعد استشارة هاتفية مجانية"
              />
            </div>
          </div>
        </Container>
      </Navbar>
      <Container className="res-con">
        <div className="row-col" style={{ paddingTop: 47 }}>
          <Col lg={6} style={{ padding: 0 }}>
            <BannerImg />
          </Col>
          <Col lg={6} className="d-flex flex-column justify-content-center">
            <div className="banner-header pc-view">
              نساعدكم على اختيار
              <br /> <span style={{ color: "#299CF7" }}>
                التخصص والجامعة
              </span>{" "}
              <br />
              المناسبة لكم
            </div>
            <div className="banner-header mobile-view">
              نساعدكم على اختيار{" "}
              <span style={{ color: "#299CF7" }}>التخصص والجامعة</span> المناسبة
              لكم
            </div>
            <div className="w-100 d-flex banner-bottom-line">
              <BottomLine />
            </div>
            <p className="banner-subtext pc-view">
              احجز موعد استشارة وتعرف على كل ما تحتاجه لتحقق
              <br />
              حلمك بالسفر للدراسة في الخارج
            </p>
            <p className="banner-subtext mobile-view">
              احجز موعد استشارة وتعرف على كل ما تحتاجه لتحقق حلمك بالسفر للدراسة
              في الخارج
            </p>
            <div className="w-100 popup-btn-home" style={{ paddingTop: 31 }}>
              <PopupButton
                className="banner-btn  calendly-btn"
                url="https://calendly.com/ulearn/website?hide_gdpr_banner=1"
                utm={{
                  utmCampaign: utm_campaign,
                  utmContent: utm_content,
                  utmMedium: utm_medium,
                  utmSource: utm_source,
                  utmTerm: utm_term,
                }}
                rootElement={document.getElementById("root")}
                text="احجز موعد استشارة هاتفية مجانية"
              />
            </div>
            <div className="pc-view">
              {" "}
              <div
                className="d-flex flex-row justify-content-end "
                style={{ padding: "30px 0" }}
              >
                <img
                  style={{ width: 91, height: 56, marginRight: 26 }}
                  src="https://storage.googleapis.com/destination_ulearn/courses/image%2033-min.png"
                  alt="img"
                />
                <img
                  style={{ width: 91, height: 56 }}
                  src="https://storage.googleapis.com/destination_ulearn/courses/image%2034-min.png"
                  alt="img"
                />
              </div>
            </div>
          </Col>
        </div>
      </Container>
      <div className=" mobile-view">
        <div
          className="d-flex flex-row justify-content-around align-items-center"
          style={{ padding: "30px 0" }}
        >
          <img
            style={{ width: 143, height: 88 }}
            src="https://storage.googleapis.com/destination_ulearn/courses/image%2033-min.png"
            alt="img"
          />
          <img
            style={{ width: 143, height: 88 }}
            src="https://storage.googleapis.com/destination_ulearn/courses/image%2034-min.png"
            alt="img"
          />
        </div>
      </div>
      <div style={{ background: "rgba(232, 233, 245, 0.25)" }}>
        <Container>
          <div className="row-col" style={{ padding: "60px 0" }}>
            <Col lg={4}>
              <div className="d-flex flex-column align-items-center">
                <span className="numberText">5+</span>
                <span className="info-section-text">سنوات خبرة</span>
                <span className="info-section-subtext">
                  جميع المستشارين معتمدين ولديهم خبرة طويلة في مجال الدراسة في
                  الخارج
                </span>
              </div>
            </Col>
            <Col lg={4}>
              <div className="d-flex flex-column align-items-center">
                <span className="numberText">15+</span>
                <span className="info-section-text">مستشار اكاديمي</span>
                <span className="info-section-subtext">
                  يساعدك ويعينك على تحقيق حلمك بالدراسة في الخارج
                </span>
              </div>
            </Col>

            <Col lg={4}>
              <div className="d-flex flex-column align-items-center">
                <span className="numberText">2,000+</span>
                <span className="info-section-text">استشارة سنوية</span>
                <span className="info-section-subtext">
                  تقديم استشارة واعطاء المعلومات المتعلقة بالدراسة في الخارج
                </span>
              </div>
            </Col>
          </div>
        </Container>
      </div>
      <div id="our-ways">
        <Container>
          <div className="consaltation-header">تتضمن الاستشارة</div>
          <div className="row-col consult-section-margin">
            <Col md={6} style={{ padding: 0 }}>
              <div className="d-flex justify-content-center">
                <div
                  className="consult-section-img1"
                  style={{
                    background: `url("https://cdn.ulearn.study/courses/Screen%20Shot%202022-03-22%20at%202.54dd%20(1)-min.png")`,
                  }}
                />
              </div>
            </Col>
            <Col md={6} style={{ padding: 0 }}>
              <div className="consaltation-text pc-view">
                البحث عن أفضل
                <br /> <b style={{ color: "#299CF7" }}>الجامعات والمدن</b>
                <br />
                المناسبة لك
              </div>
              <div className="consaltation-text mobile-view">
                البحث عن أفضل{" "}
                <b style={{ color: "#299CF7" }}>الجامعات والمدن</b> المناسبة لك
              </div>
              <div className="consaltation-subtext">
                تقديم الاستشارة الكافية للإجابة على جميع اسئلتكم المتعلقة
                بالدراسة في الخارج والبحث عن أفضل المدن والجامعات من ناحية
                التخصص والتكلفة.
              </div>
              <div className="w-100 d-flex justify-content-end">
                <PopupButton
                  className="banner-btn"
                  utm={{
                    utmCampaign: utm_campaign,
                    utmContent: utm_content,
                    utmMedium: utm_medium,
                    utmSource: utm_source,
                    utmTerm: utm_term,
                  }}
                  url="https://calendly.com/ulearn/website?hide_gdpr_banner=1"
                  rootElement={document.getElementById("root")}
                  text="احجز موعد استشارة هاتفية مجانية"
                />
              </div>
            </Col>
          </div>
        </Container>
      </div>

      <div>
        <Container>
          <div className="row-col-rev consult-section-margin">
            <Col
              lg={6}
              style={{ padding: 0 }}
              className="d-flex flex-column justify-content-center"
            >
              <div className="consaltation-text">
                المساعدة في الحصول على{" "}
                <b style={{ color: "#299CF7" }}>القبول الجامعي</b>{" "}
              </div>
              <div className="consaltation-subtext">
                المساعدة في الحصول على قبول غير مشروط من الجامعة والتأكد أن كل
                الأوراق والمستلزمات جاهزة.
              </div>
              <div className="w-100 d-flex justify-content-end">
                <PopupButton
                  className="banner-btn"
                  utm={{
                    utmCampaign: utm_campaign,
                    utmContent: utm_content,
                    utmMedium: utm_medium,
                    utmSource: utm_source,
                    utmTerm: utm_term,
                  }}
                  url="https://calendly.com/ulearn/website?hide_gdpr_banner=1"
                  rootElement={document.getElementById("root")}
                  text="احجز موعد استشارة هاتفية مجانية"
                />
              </div>
            </Col>
            <Col lg={6} style={{ padding: 0 }}>
              <div className="d-flex justify-content-center">
                <div
                  className="consult-section-img1"
                  style={{
                    background: `url("https://cdn.ulearn.study/courses/Screen%20Shot%202022-03-22%20at%202.54-min.png")`,
                  }}
                />
              </div>
            </Col>
          </div>
        </Container>
      </div>

      <div>
        <Container>
          <div className="row-col consult-section-margin">
            <Col md={6} style={{ padding: 0 }}>
              <div className="d-flex justify-content-center">
                <div
                  className="consult-section-img1"
                  style={{
                    background: `url("https://cdn.ulearn.study/courses/Screen%20Shot%202022-03-22%20at%202.54%20(1)-min.png")`,
                  }}
                />
              </div>
            </Col>
            <Col md={6} style={{ padding: 0 }}>
              <div className="consaltation-text">
                {" "}
                <b style={{ color: "#299CF7" }}> مستشارين أكاديميين</b> جاهزين
                للإجابة على اسئلتكم
              </div>
              <div className="consaltation-subtext">
                مستشارين أكاديميين مختصين يجاوبون على جميع استفساراتكم وأسئلتكم.{" "}
              </div>
              <div className="w-100 d-flex justify-content-end">
                <PopupButton
                  className="banner-btn"
                  utm={{
                    utmCampaign: utm_campaign,
                    utmContent: utm_content,
                    utmMedium: utm_medium,
                    utmSource: utm_source,
                    utmTerm: utm_term,
                  }}
                  url="https://calendly.com/ulearn/website?hide_gdpr_banner=1"
                  rootElement={document.getElementById("root")}
                  text="احجز موعد استشارة هاتفية مجانية"
                />
              </div>
            </Col>
          </div>
        </Container>
      </div>

      <div>
        <Container className="d-flex justify-content-center">
          <div className="abstract-section">
            <span
              className="abstract-text-section"
              style={{
                textAlign: "right",
                fontFamily: "arabic",
                fontWeight: 400,
                color: "#fff",
                fontSize: 70,
                float: "right",
                lineHeight: "70px",
                height: 214,
                padding: "0px 80px",
                display: "flex",
                alignItems: "center",
                direction: "rtl",
              }}
            >
              اختبار تحديد التخصص
            </span>
            <div
              className={"h-100 w-100 d-flex justify-content-start"}
              style={{
                position: "absolute",
                top: 0,
              }}
            >
              <div
                className={"abstract-section-img"}
                style={{ borderRadius: 15 }}
              />
              {/* <div
                            style={{
                                borderRadius: 15,
                            }}
                            className="h-100 w-100 justify-content-center align-items-center"
                        >                          
                        </div> */}
              <div className="d-flex res-w-100 justify-content-center h-100  abstract-btn-con">
                <a href="/career" target="_blank" className="res-w-100">
                  <Button
                    style={{ border: "none" }}
                    className="abstract-section-btn"
                    //   onClick={() => router.push(`${country}/pricing`)}st
                  >
                    ابدأ الاختبار
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </Container>
      </div>

      <div className="partner-section">
        <Container>
          <div className="partner-section-header">بعض شركائنا</div>
          <div className="partner-section-text">
            لدينا شراكات مع أكثر من 200 جامعة ومعهد حول العالم
          </div>
        </Container>
        <LogoSlider />
        {/* <img
            src="https://storage.googleapis.com/destination_ulearn/courses/Group%20577-min.png"
            alt="icon"
            className="partner-img"
          /> */}
      </div>

      <Container className="contact-section-padding">
        <div className="d-flex flex-column align-items-center jusctify-content-center">
          <ChatIcon />
          <div className="contact-section-header">
            عندك اسئلة معينة عن الدراسة في الخارج؟
          </div>
          <div className="contact-section-text">نجيب على جميع استفساراتكم</div>
          <a
            className="contact-btn"
            href={"https://wa.me/96566687847"}
            target="_blank"
            rel="noreferrer"
          >
            (+965) 66687847
            <img
              src="https://storage.googleapis.com/destination_ulearn/courses/WhatsApp_Logo_1%201.png"
              alt="img"
              width="32px"
              height="32px"
              style={{ marginLeft: 8 }}
            />
          </a>
        </div>
      </Container>
      <div>
        <Footer
          utm_source={utm_source}
          utm_content={utm_content}
          utm_medium={utm_medium}
          utm_campaign={utm_campaign}
          query={query}
          utm_term={utm_term}
        />
      </div>
      <a
        className="whatsapp-fixed-icon-con pc-view"
        target="_blank"
        href={"https://wa.me/96566687847"}
      >
        <img
          className="whatsapp-fixed-icon-con"
          src="https://storage.googleapis.com/destination_ulearn/courses/whatsappIcon.png"
          alt="icon"
        />
      </a>
    </div>
  );
}
