import React from "react";
import Logo from "../../Assets/Images/Logo";

export default function Header(props) {
  return (
    <div className=" nav-con" style={{ background: "#fff" }}>
      <div className="container align-items-center d-flex justify-content-between w-100">
        <a href="/">
          {" "}
          <Logo />
        </a>
        {props.onBoarding ? (
          props.onBoarding
        ) : (
          <button
            onClick={() => props.onClick()}
            className="custom-btn"
            style={{ width: 162, height: 43 }}
          >
            ابدأ الاختبار
          </button>
        )}
      </div>
    </div>
  );
}
