import * as React from "react";
import { CloseIcon } from "../../Assets/Images/CloseIcon";
import {
  BadgeCon,
  BadgeText,
  InputComponent,
  SubjectsContainer,
  Text,
} from "./styled";

export const UniversityInputComponent = (props) => {
  const {
    selectedUniversity,
    setselectedUniversity,
    setsearchText,
    arabic,
    universities,
    searchUniversity,
    searchText,
    setuniversities,
    t,
    seterrorMessage,
    error,
    loading,
    university,
    onClickNext, // now includes recommended universities
  } = props;

  const [closeMenu, setcloseMenu] = React.useState(false);
  const dropdownRef = React.useRef(null);

  const handleSearch = async (e) => {
    seterrorMessage("");
    setsearchText(e.target.value);
    setcloseMenu(true);
    await searchUniversity(e);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setcloseMenu(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelectUniversity = (university) => {
    if (
      selectedUniversity.length < 5 &&
      !selectedUniversity.some((item) => item.id === university.id) // Ensure no duplicate
    ) {
      setselectedUniversity([...selectedUniversity, university]);
      setsearchText(""); // Clear input when a university is selected
      setuniversities([]); // Clear universities after selection
      setcloseMenu(false); // Close dropdown
      seterrorMessage("");
    } else if (selectedUniversity.length === 5) {
      seterrorMessage(t("common:universityError"));
    }
  };

  return (
    <div className="res-w-100">
      <div className="d-flex w-100 justify-content-center">
        <div
          className="d-flex res-w-100 flex-row"
          style={{ position: "relative" }}
          ref={dropdownRef}
        >
          <InputComponent
            placeholder={`${
              selectedUniversity.length
                ? t("common:universityAddmorePlaceholder")
                : t("common:universityPlaceholder")
            }`}
            name="name"
            onChange={handleSearch}
            type="text"
            autoComplete="off"
            value={searchText}
            arabic={arabic}
            onClick={() => setcloseMenu(true)}
          />

          {closeMenu && (
            <div
              className="d-flex flex-column"
              style={{
                background: "#fff",
                position: "absolute",
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                borderRadius: 8,
                top: 60,
                width: "100%",
                padding: "4px 0",
              }}
            >
              {loading ? (
                <div
                  className="d-flex justify-content-center"
                  style={{ color: "#969696" }}
                >
                  Loading...
                </div>
              ) : (
                universities
                  .filter(
                    (uni) =>
                      !selectedUniversity.some(
                        (selected) => selected.id === uni.id
                      )
                  )
                  .map((a) => (
                    <Text
                      key={a.id}
                      onClick={() => handleSelectUniversity(a)}
                      className="custom-dd-menu"
                      style={{ padding: "5px 15px" }}
                      arabic={arabic}
                    >
                      {a.name}
                    </Text>
                  ))
              )}
            </div>
          )}
        </div>
      </div>
      {error}
      <SubjectsContainer className="d-flex flex-row justify-content-center">
        {selectedUniversity?.map((a) => (
          <BadgeCon
            key={a.id}
            className="bg-grey-100 text-sm font-medium py-3 px-6 rounded-full cursor-pointer"
            style={{ backgroundColor: "#299cf714" }}
          >
            <BadgeText arabic={arabic} style={{ color: "#299cf7" }}>
              {a.name.length > 30
                ? `${a.name.replace(/-/g, " ").substring(0, 30)}...`
                : a.name}
            </BadgeText>
            <CloseIcon
              color={"#299cf7"}
              onClick={() => {
                const deleted = selectedUniversity?.filter(
                  (z) => z.id !== a.id
                );
                setselectedUniversity(deleted);
                seterrorMessage("");
              }}
              width="14px"
              height="14px"
            />
          </BadgeCon>
        ))}

        {/* {university
          .filter(
            (uni) => !selectedUniversity.some((item) => item.id === uni.id)
          )
          .slice(0, 5 - selectedUniversity.length)
          .map((uni) => (
            <BadgeCon
              key={uni.id}
              onClick={() => handleSelectUniversity(uni)}
              className="bg-grey-100 text-sm font-medium py-3 px-6 rounded-full cursor-pointer"
              style={{
                backgroundColor: selectedUniversity.some(
                  (item) => item.id === uni.id
                )
                  ? "#FD3E60"
                  : "#e8e9f5b2",
              }}
            >
              <BadgeText arabic={arabic}>
                {uni.name.length > 30
                  ? `${uni.name.replace(/-/g, " ").substring(0, 30)}...`
                  : uni.name}
              </BadgeText>
            </BadgeCon>
          ))} */}

        {selectedUniversity.length ? null : (
          <BadgeCon
            onClick={() => onClickNext()}
            className="bg-grey-100 text-sm font-medium py-3 px-6 rounded-full cursor-pointer"
          >
            <BadgeText arabic={arabic}>{t(`common:notSure`)}</BadgeText>
          </BadgeCon>
        )}
      </SubjectsContainer>
    </div>
  );
};
