// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Button = styled.button(
  ({ disableBtnStyle, btnType, width, loading, langBtn }) => `
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  width: ${width};
  height: 43.94px;
  transition: all 0.3s linear;
  background: ${
    loading
      ? "#fff"
      : disableBtnStyle
      ? "#E7EAEE"
      : btnType === "dark"
      ? "#299cf7"
      : "#fff"
  };
  border:  ${
    disableBtnStyle
      ? "none"
      : `1px solid
    ${btnType === "dark" ? "#299cf7" : "#1E2432"}`
  };
   border-radius:50px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  font-family: "Cairo";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-transform: capitalize;
  color:   ${
    disableBtnStyle ? "#A0ABBB" : btnType === "light" ? "#1D2939" : "#fff"
  };
  &:hover {
    background: ${
      disableBtnStyle ? "#E7EAEE" : btnType === "light" ? "#299cf7" : "#fff"
    };
    cursor: ${disableBtnStyle ? "not-allowed" : "pointer"};
    color: ${
      disableBtnStyle ? "#A0ABBB" : btnType === "dark" ? "#1D2939" : "#fff"
    };
    border: ${disableBtnStyle ? "none" : `1px solid "#1D2939"`};
  }
  @media (max-width: 992px) {
    width: ${langBtn ? "max-contant !important" : "100% !important"} ;
  }

  
        `
);
