import * as React from "react";
import { CloseIcon } from "../../Assets/Images/CloseIcon";
import { InputComponent, Text, BadgeCon, BadgeText, Flag } from "./styled";

export const CountryInputComponent = ({
  selectedCountry,
  setsearchText,
  setselectedCountry,
  arabic,
  searchCountry,
  countries,
  searchText,
  setcountries,
  placeholder,
  className,
  loading,
  recommendedCountries,
  setRecommendedCountries,
  onClickNext,
  formInput,
  submitForm,
}) => {
  const [isInputFocused, setIsInputFocused] = React.useState(false);
  const dropdownRef = React.useRef(null);
  const isCountrySelected = Object.keys(selectedCountry).length > 0;
  const hasCountries = countries.length > 0;

  function getCountryEmoji(countryCode) {
    if (!countryCode) return undefined;

    const OFFSET = 127397;
    const codePoints = countryCode
      .toUpperCase()
      .split("")
      .map((char) => char.charCodeAt(0) + OFFSET);

    return String.fromCodePoint(...codePoints);
  }

  const handleClearSelection = () => {
    setselectedCountry({});
    setsearchText("");
    setcountries([]);
    setRecommendedCountries([
      {
        english_name: "Kuwait",
        arabic_name: "الكويت",
        alpha2_code: "KW",
        alpha3_code: "KWT",
        phone_code: "965",
        id: "63cfb299df954c47a6fde217",
      },
      {
        english_name: "Saudi Arabia",
        arabic_name: "السعودية",
        alpha2_code: "SA",
        alpha3_code: "SAU",
        phone_code: "966",
        id: "63cfb299df954c47a6fde264",
      },
      {
        english_name: "Qatar",
        arabic_name: "قطر",
        alpha2_code: "QA",
        alpha3_code: "QAT",
        phone_code: "974",
        id: "63cfb299df954c47a6fde255",
      },
      {
        english_name: "Oman",
        arabic_name: "سلطنة عمان",
        alpha2_code: "OM",
        alpha3_code: "OMN",
        phone_code: "968",
        id: "63cfb299df954c47a6fde248",
      },
      {
        english_name: "United Arab Emirates",
        arabic_name: "الإمارات العربية المتحدة",
        alpha2_code: "AE",
        alpha3_code: "AE",
        phone_code: "971",
        id: "63cfb299df954c47a6fde28d",
      },
    ]);
  };

  const handleCountrySelect = (country) => {
    setselectedCountry(country);
    onClickNext(country);
    setsearchText("");
    setcountries([]);
    setIsInputFocused(false);
    setRecommendedCountries([]);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsInputFocused(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="d-flex w-100 justify-content-center">
        <div
          className="d-flex res-w-100 flex-row"
          style={{ position: "relative" }}
        >
          {isCountrySelected && (
            <div
              onClick={handleClearSelection}
              style={{
                right: !arabic ? 20 : "auto",
                left: arabic ? 20 : "auto",
              }}
              className="input-cross"
            >
              <CloseIcon />
            </div>
          )}
          <InputComponent
            name="country"
            type="text"
            className={` ${className}`}
            style={{ textAlign: arabic ? "right" : "left" }}
            value={
              isCountrySelected
                ? arabic
                  ? selectedCountry.arabic_name
                  : selectedCountry.english_name
                : searchText
            }
            placeholder={isCountrySelected ? undefined : placeholder}
            onChange={(e) => {
              setsearchText(e.target.value);
              searchCountry(e);
            }}
            onFocus={() => setIsInputFocused(true)}
            autoComplete="off"
            arabic={arabic}
          />

          {!isCountrySelected && searchText && (
            <div
              ref={dropdownRef}
              className="d-flex flex-column"
              style={{
                background: "#fff",
                position: "absolute",
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                borderRadius: 8,
                zIndex: 99,
                top: 60,
                width: "100%",
                padding: "4px 0",
              }}
            >
              {loading ? (
                <div
                  className="d-flex justify-content-center"
                  style={{ padding: "10px 0", color: "#969696" }}
                >
                  Loading...
                </div>
              ) : hasCountries ? (
                countries.map((country) => (
                  <Text
                    key={country.id}
                    onClick={() => handleCountrySelect(country)}
                    className="custom-dd-menu"
                    style={{ padding: "5px 15px" }}
                    arabic={arabic}
                  >
                    {arabic ? country.arabic_name : country.english_name}
                  </Text>
                ))
              ) : (
                <div
                  className="d-flex justify-content-center"
                  style={{ padding: "10px 0", color: "#969696" }}
                >
                  No result found
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {!submitForm ? (
        <>
          {isCountrySelected && !formInput && (
            <div className="d-flex flex-wrap">
              <BadgeCon
                key={selectedCountry.id}
                onClick={() => handleCountrySelect(selectedCountry)}
                className="bg-grey-100 text-sm font-medium py-3 px-6 rounded-full cursor-pointer"
                style={{
                  marginRight: "8px",
                  marginBottom: "8px",
                  color: "#fff",
                  backgroundColor: "#299cf714",
                }}
              >
                <Flag>{getCountryEmoji(selectedCountry.alpha2_code)}</Flag>
                <BadgeText
                  arabic={arabic}
                  style={{ color: "#299cf7", fontWeight: 700 }}
                >
                  {arabic
                    ? selectedCountry.arabic_name
                    : selectedCountry.english_name}
                </BadgeText>
              </BadgeCon>
            </div>
          )}
        </>
      ) : null}
      {!submitForm ? (
        <>
          {" "}
          {!isCountrySelected && recommendedCountries.length > 0 && (
            <div
              style={{ direction: arabic ? "rtl" : "ltr" }}
              className="d-flex flex-wrap res-con-width"
            >
              {recommendedCountries.map((country) => (
                <BadgeCon
                  key={country.id}
                  onClick={() => handleCountrySelect(country)}
                  className="bg-grey-100 text-sm font-medium py-3 px-6 rounded-full cursor-pointer"
                  style={{
                    marginRight: "8px",
                    cursor: "pointer",
                    marginBottom: "8px",
                    color:
                      selectedCountry.id === country.id
                        ? "#299cf7"
                        : "#E8E9F5B2",
                    backgroundColor:
                      selectedCountry.id === country.id
                        ? "#299cf714"
                        : "#E8E9F5B2",
                  }}
                >
                  <Flag>{getCountryEmoji(country.alpha2_code)}</Flag>
                  <BadgeText
                    arabic={arabic}
                    style={{
                      color:
                        selectedCountry.id === country.id ? "#fff" : "#191d23",
                      fontWeight: 700,
                    }}
                  >
                    {arabic ? country.arabic_name : country.english_name}
                  </BadgeText>
                </BadgeCon>
              ))}
            </div>
          )}
        </>
      ) : null}
    </>
  );
};
