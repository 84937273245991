/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";

const GoogleForm = () => {
  return (
    <div style={{ backgroundColor: "#E4F3FE", height: "100vh" }}>
      <iframe
        src={`https://docs.google.com/forms/d/e/1FAIpQLSeLF6OvMYa3T64y07P1VlX6QLLq6BI02Uyxs2gDQps6u-C7iA/viewform?embedded=true&`}
        width="100%"
        height="100%"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
      >
        Loading….
      </iframe>
    </div>
  );
};

export default GoogleForm;
