import {
  SAVE_MAJOR,
  SEARCH_SUBJECT,
  UNIVERSITIES,
  SEARCH_COUNTRY,
  LEADS,
} from "../API";
import Axios from "axios";

export const saveMajor = async (data) => {
  try {
    const res = await Axios.post(`${SAVE_MAJOR}`, {
      data,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const searchSubjects = async (data) => {
  try {
    const res = await Axios.get(`${SEARCH_SUBJECT}/${data.text}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const searchUniversities = async (data) => {
  try {
    const res = await Axios.get(`${UNIVERSITIES}/search/${data.text}`, {
      params: {
        limit: data.limit,
        sortBy: data.sortBy,
      },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const createLeads = async (data) => {
  try {
    const res = await Axios.post(`${LEADS}`, {
      ...data,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getUniversity = async () => {
  try {
    const res = await Axios.get(`${UNIVERSITIES}`);
    return res.data;
  } catch (error) {
    return error;
  }
};
export const searchCountries = async (data) => {
  try {
    const res = await Axios.get(`${SEARCH_COUNTRY}/${data.text}`, {
      params: {
        limit: data.limit,
        sortBy: data.sortBy,
      },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const createStudent = async (data) => {
  try {
    console.log(data);
    const res = await Axios.post(`${LEADS}`, {
      ...data,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};
